body{
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    font-family: Roboto;
    color:#333;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.page{
    width: 1054px;
    max-width: 100%;
    margin: 0 auto;
    padding:  0 15px;
}
.page400{
    width: 400px;
}
.page1024{
    width: 1024px;
}

.page .MuiCardHeader-root{
    padding: 16px 0;
}



.title {
  color: #333;
    font-family: arial;
    font-size: 20px;
    margin: 0 0 30px 0;
}

.page_header{
    margin-bottom: 60px !important;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size:80%
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px;
  }
  

.footer{
    border-top: 1px solid #d4d4d4;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    font-size: 14px;
}

.pageBody{
    min-height: calc(100vh - 112px - 60px - 55px - 60px);
}

.MB15{
    margin-bottom: 15px !important;
}
.MB23{
    margin-bottom: 23px !important;
}
.MB30{
    margin-bottom: 30px !important;
}
.MB60{
    margin-bottom: 60px !important;
}
.MT4{
    margin-top: 4px !important;
}

.radioButtons{
    padding-bottom: 5px;
}

.page .MuiTypography-body1{
    font-size: 16px;
}

.leftColumn{
    padding-right: 60px;
}


@media only screen and (max-width: 599px) {
    .leftColumn{
        padding-right: 0px;
    }
}

@media only screen and (max-width: 750px) {
    h2{
        font-size: 18px;
    }
}

h2 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


.icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-359deg);
              transform: rotate(-359deg);
    }
  }
  
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-359deg);
              transform: rotate(-359deg);
    }
  }
